.st-pusher {
  position: relative;
  left: 0;
  -webkit-transition: -webkit-transform 0.5s;
  transition: all 0.5s;
}

.st-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: none;
  overflow-y: scroll;
}

.st-menu-open .st-overlay {
  display: block;
  z-index: 1000;
}

.st-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  visibility: hidden;
  width: 300px;
  height: 100%;
  overflow: auto;
  background: $light-background;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  padding-bottom: 30px;
}

.st-menu::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.2);
  content: '';
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.st-menu-right {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1001;
  visibility: hidden;
  width: 300px;
  height: 100%;
  overflow: auto;
  background: $light-background;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.st-menu-right::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.2);
  content: '';
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.st-menu-open {
  overflow: hidden;
}
.st-menu-open .st-wrapper {
  overflow-y: scroll;
}

.st-menu-open .st-menu::after {
  width: 0;
  height: 0;
  opacity: 0;
  -webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
  transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
}

.st-menu-close {
  position: absolute;
  top: 20px;
  right: 8px;
  cursor: pointer;
  z-index: 1;
  &:hover {
    color: $icon-color;
  }
  i {
    font-weight: 700;
  }
}
.st-menu-right .st-menu-close {
  right: auto;
  left: 8px;
}

.st-menu-title {
  padding: 15px 5px;
  text-align: center;
}

/* Individual effects */

.st-effect-left.st-menu {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.st-effect-left.st-menu-open .st-effect-left.st-menu {
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.st-effect-left.st-menu::after {
  display: none;
}

.st-effect-right.st-menu-right {
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.st-effect-right.st-menu-open .st-effect-right.st-menu-right {
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.st-effect-right.st-menu-right::after {
  display: none;
}

/* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */
.no-csstransforms3d .st-pusher,
.no-js .st-pusher {
  padding-left: 300px;
}
