$category-block-padding: 20px;
.aone-featuredcategories {
  margin-bottom: $category-block-padding;
  .title-block {
    .title-icon {
      margin-top: -3px;
      margin-right: 8px;
    }
  }

  .category-list-flex {
    display: flex;
    flex-wrap: wrap;
    .category-block {
      width: 100%;
    }
  }
  .category-list-slider {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    &.slick-slider {
      display: block;
      overflow: visible;
    }
  }
  
  .category-list {
    margin: 0 ($category-block-padding / -2);
    &:not(.slick-slider) {
      .category-block {
        @include make-col(1, 1);
      }
      @include media-breakpoint-up(md) {
        &.per-row-4, &.per-row-3, &.per-row-2 {
          .category-block {
            @include make-col(1, 2);
          }
        }
      }
      @include media-breakpoint-up(lg) {
        &.per-row-3 {
          .category-block {
            @include make-col(1, 3);
          }
        }
        &.per-row-4 {
          .category-block {
            @include make-col(1, 4);
          }
        }
      }
    }
  }

  .category-block {
    padding: 0 ($category-block-padding / 2);
    margin-bottom: 19px;
    margin-top: 1px;
    .category-container {
      overflow: hidden;
      height: 100%;
      background: $box-background;
      box-shadow: 0px 1px 5px $box-shadow-color;
      border-radius: 5px;
    }
    .category-image {
      text-align: center;
      img {
        margin: 0 auto;
        &:hover {
          filter: brightness(75%);
        }
      }
    }
    .category-name {
      font-size: 1.125rem;
      text-align: center;
      text-transform: uppercase;
      margin: 0;
      padding: 10px 5px;
      background: $color_gray;
    }
    .category-des {
      text-align: center;
      padding: 10px;
      display: none;
    }
    .sub-categories {
      text-align: left;
      overflow: hidden;
      padding: 10px 10px;
      li {
        padding: 7px 5px;
        width: 100%;
        @include media-breakpoint-down(sm) {
          width: 50%;
        }
      }
    }
    .shop-now {
      text-align: center;
      margin-top: 25px;
      display: none;
    }
  }
  
  @include media-breakpoint-up(xl) {
    .per-row-3 .category-block .sub-categories li, .per-row-2 .category-block .sub-categories li {
      width: 50%;
    }
  }
  @include media-breakpoint-up(lg) {
    .per-row-2 .category-block .sub-categories li {
      width: 50%;
    }
  }
}