.home_services,
#custom-text {
  background: none;
  margin-bottom: 0;
  ul {
    display: flex;
    list-style: none;
    justify-content: space-between;
    padding-left:0 !important;
    li {
      list-style: none;
      flex-basis: 270px;
      height: 160px;
      background-image: url(../img/service_bg.jpg);
      background-size: auto 100%;
      border-radius: 0;
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      transition: all 0.2s ease;
      cursor: pointer;
      img {
        width: 40px;
        height: 40px;
        margin-bottom: 1rem;
      }
      font-size: 1.2rem;
      a {
        font-size: 0.8rem;
        margin-top:1rem;
      }
      &:nth-child(1) {
        background-position: 0 center;
      }
      &:nth-child(2) {
        background-position: -280px center;
      }
      &:nth-child(3) {
        background-position: -560px center;
      }
      &:nth-child(4) {
        background-position: -840px center;
      }
      &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
        transform: translate(0, -3px);
      }
    }
  }
}
/* header color override */
.header-main-menu {
  background: #bbb5b4;
}
.header-event-banner-wrapper {
  color: white;
  padding: 4px 0 !important;
  font-weight: 700;
  a {
    border-bottom: none !important;
    cursor:pointer;
  }
}
/* mega menu overriding */
.anav-top {
  .amenu-link {
    color: white;
    font-size: 0.9rem;
    padding: 13px 8px !important;
  }
  .amenu-item {
    &:hover {
      .amenu-link {
        @media (min-width: 768px) {
          color: #3d3d3d;
        }
      }
    }
  }
}
/* nav tabs override */
.aone-tabs {
  .nav-link {
    color: $mm-color-tortora;
  }
}
/* footer override */
.hook-display-footer {
  .linklist:last-child {
    font-family: "Roboto Condensed", sans-serif;
    text-transform: uppercase;
    font-size: 1.25rem;
    margin-bottom: 12px;
    color: #3d3d3d;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: inherit;
    li a:before,h4 {
      display:none;
    }
  }
}
.footer-bottom {
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
  .credits {
    ul {
      display: flex;
      @include media-breakpoint-down(sm) {
        justify-content: center;
      }
      li {
        padding-right: 1rem;
        a {
          font-weight: 700;
        }
      }
    }
  }
  .payment-logo {
    text-align: right;
    @include media-breakpoint-down(sm) {
      text-align: center;
      margin-bottom: 15px;
    }
  }
}
/* slick slider override */
.slick-prev, .slick-next {
  width: 40px;
  height: 40px;
  background-color: transparent;
  background-image: none;
  color: $mm-color-supergray;
  border: 0;
  border-radius: 50%;
}
.slick-prev {
  right: 55px;
  background-image: url(../img/arrow_left_dark.svg);
  background-size: 100% 100%;
}
.slick-next {
  background-image: url(../img/arrow_right_dark.svg);
  background-size: 100% 100%;
}
.slick-next:before,
.slick-prev:before {
  content: "";
}
/* nivo slider override */
.aone-slideshow.theme-default a.nivo-prevNav {
  background-image: url(../img/arrow_left_light.svg);
  background-size: 100% 100%;
}
.aone-slideshow.theme-default a.nivo-nextNav {
  background-image: url(../img/arrow_right_light.svg);
  background-size: 100% 100%;
}
.aone-slideshow.theme-default .nivo-directionNav a {
  background-color: transparent;
}
.aone-slideshow.theme-default a.nivo-prevNav:before {
  content: "";
}
.aone-slideshow.theme-default a.nivo-nextNav:before {
  content: "";
}

#footer {
  .scroll-to-top-button {
    a {
      display: block;
      width: 40px;
      height: 40px;
      border: 2px solid;
      line-height: 36px;
      font-size: 25px;
      color: $mm-color-supergray;
      background-color: transparent;
      border: 0;
      background-image: url(../img/arrow_right_dark.svg);
      transform:  rotate(270deg);
      i {
        display: none;
      }
    }
  }
}

/* various colors */
.product-flag.discount span,
.discount-percentage {
  background-color: $mm-color-orange !important;
}
.product-price {
  color: $mm-color-blue;
}
.search-widget [type="submit"] {
  background-color: $mm-color-supergray;
}
#header .left-nav-icon {
  color: $mm-color-supergray;
}
.title-block {
  color: $mm-color-tortora;
}
/* Nivo Slider override */
.aone-slideshow {
  .nivo-caption a {
    display: inline-block;
    border-radius: 3px;
    padding: 10px 15px;
    position: relative;
    white-space: nowrap;
    transition: all 0.3s;
    background-color: $mm-color-red;
    color:white;
    margin-top: 2rem;
    &::after {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 15px;
      vertical-align: 4px;
      content: "";
      border-bottom: .3em solid;
      border-right: .3em solid transparent;
      border-top: 0;
      border-left: .3em solid transparent;
      transform:  rotate(90deg);
    }
  }
}
.aone-slideshow:first-child {
  .nivo-caption a {
    background-color: transparent;
  }
}
.product-list-wrapper.columns-5 .product-style .product-container {
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .second-block {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .product-name {
      flex-grow: 1;
    }
  }
}

/* override various blocks */
.home_main_text {
  h1 {
    text-transform: uppercase;
    color: $mm-color-tortora;
  }
}
.home-blocks-bottom .title-block {
  justify-content: center;
}