/*** SOME GENERIC STYLES ***/
@mixin customer-area-base-box {
  @include box-shadow;
  background: white;
  padding: 16px;
}
@mixin customer-area-box {
  @include customer-area-base-box;
  font-size: $font-size-sm;
  color: $gray-800;
}

.account-list {
  .logout {
    color: red;
    &:before {
      display: none;
    }
    i {
      min-width: 18px;
    }
  }
}
.footer-account-links {
  display: flex;
  flex-wrap: wrap;
  li {
    + li {
      margin-left: 3%;
    }
  }
  i {
    min-width: 18px;
  }
  .logout-link {
    margin-left: auto;
    a {
      color: red;
    }
  }
}

.table-labeled {
  th {
    vertical-align: middle;
  }
  td {
    vertical-align: middle;
  }
  .label {
    font-weight: 400;
    border-radius: 3px;
    font-size: inherit;
    padding: 0.25rem 0.375rem;
    color: white;
    white-space: nowrap;
  }
}
.page-order {
  .table {
    margin-bottom: 0;
  }
  table {
    th {
      padding: 8px;
    }
    td {
      padding: 8px;
    }
    thead {
      th {
        text-align: center;
      }
    }
  }
}
#authentication {
  .tooltip.tooltip-bottom {
    padding: 0;
    margin: 0;
  }
}
#identity,
#authentication {
  .radio-inline {
    padding: 0;
    .custom-radio {
      margin-right: 0;
    }
  }
}
/*** Most of the customer accpunt pages ***/
.page-customer-account {
  .forgot-password {
    a {
      color: inherit;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .no-account {
    text-align: center;
  }
  hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
/*** Login page ***/
.page-authentication, .page-password {
  #main {
    max-width: 740px;
    margin: 0 auto;
  }
}
/*** Addresses page ***/
.page-addresses {
  .address {
    margin-bottom: 20px;
    .address-body {
      address {
      }
    }
    .address-footer {
      border-top: 1px solid $border-color-lighten;
      padding-top: $box-padding;
      a + a {
        margin-left: 10%;
      }
    }
  }
  .addresses-footer {
  }
}
/*** Order details page ***/
.page-order-detail {
  .box {
    margin-bottom: 20px;
    @extend .light-box-bg;
  }
  #order-infos {
    ul {
      margin: 0;
    }
  }
  #order-history {
    .history-lines {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
      .history-line {
        padding: 0.5rem 0;
        .row {
          align-items: center;
        }
        + .history-line {
          border-top: 1px solid $border-color-lighten;
        }
        .label {
          display: inline-block;
          margin: 4px 0;
          padding: 4px 6px;
          color: white;
          border-radius: 3px;
        }
      }
    }
  }
  #order-products {
    &.return {
      margin-bottom: $box-padding;
      th {
        &.head-checkbox {
          width: 30px;
        }
      }
      td {
        &.qty {
          .current {
            width: 30%;
            float: left;
            text-align: right;
            padding-right: 0.5rem;
            padding-top: $base-padding;
          }
          .select {
            width: 70%;
            float: left;
            padding-left: 0.25rem;
            select {
            }
          }
        }
      }
    }
  }
  .order-items {
    padding-top: 0;
    .order-item {
      padding: $box-padding 0 0;
      + .order-item {
        border-top: 1px solid $border-color-lighten;
      }
      .checkbox {
        width: 30px;
        float: left;
        padding: 0 15px;
      }
      .content {
        width: calc(100% - 30px);
        float: left;
        padding: 0 15px;
      }
      .desc {
        margin-bottom: $box-padding;
      }
      .qty {
        margin-bottom: $box-padding;
        .q {
          margin-bottom: 4px;
        }
        .s {
          margin-bottom: 4px;
        }
      }
    }
  }
  .messages {
    .message {
      margin-top: 8px;
      border-bottom: 1px solid $border-color-lighten;
      &:last-child {
        border-bottom: 0;
      }
      > div {
        margin-bottom: 8px;
      }
    }
  }
  .customization {
  }
  .shipping-lines {
    padding-top: 0;
    padding-bottom: 0;
    .shipping-line {
      padding: $box-padding 0;
      + .shipping-line {
        border-top: 1px solid $border-color-lighten;
      }
    }
  }
}

/*** Credit slip page ***/
.credit-slips {
  padding-top: 0;
  padding-bottom: 0;
  .credit-slip {
    padding: $box-padding 0;
    + .credit-slip {
      border-top: 1px solid $border-color-lighten;
    }
  }
}


/*** Order return page ***/
.order-returns {
  padding-top: 0;
  padding-bottom: 0;
  .order-return {
    padding: $box-padding 0;
    + .order-return {
      border-top: 1px solid $border-color-lighten;
    }
  }
}
#order-return-infos {
  .customization {
    margin-top: 12px;
  }
}

/*** My account landing page ***/
.page-my-account {
  #content {
    .links {
      a {
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 20px;
        color: inherit;
        span.link-item {
          display: block;
          height: 100%;
          @extend .light-box-bg;
        }
        i {
          display: block;
          font-size: 2.6rem;
          width: 100%;
          padding-bottom: 3.4rem;
        }
        &:hover {
          color: $link-hover-color;
        }
      }
    }
  }
}
/*** History page ***/
#history {
  table {
    .pdf-icon {
      font-size: 20px;
    }
    .order-actions {
      a {
        display: block;
        white-space: nowrap;
      }
    }
  }
  .orders {
    .order {
      + .order {
        margin-top: 10px;
      }
      .label {
        font-weight: 400;
        border-radius: 3px;
        font-size: inherit;
        padding: 0.25rem 0.375rem;
        color: white;
        white-space: nowrap;
      }
      .status {
        margin-top: 5px;
      }
      .action {
        font-size: 150%;
        + .action {
          margin-top: 5px;
        }
      }
    }
  }
}

#order-slip {
  .pdf-icon {
    font-size: 20px;
  }
}
